// src/redux/reducers.js
import { combineReducers } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import domainSlice from './domainSlice';
import tweetSlice from './tweetSlice';
import articleSlice from './articleSlice';
import postSlice from './postSlice';
import workerStateSlice from './workerStateSlice';
import twitterAuthSlice from './twitterAuthSlice';

const rootReducer = combineReducers({
  user: userSlice,
  domain: domainSlice,
  tweet: tweetSlice,
  post: postSlice,
  article: articleSlice,
  workerState: workerStateSlice,
  twitterAuth: twitterAuthSlice
});

export default rootReducer;
