// redux/twitterAuthSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTwitterAuthLink,
  postArticleTweet,
  deleteArticleTweet
} from '../helpers/requests';

export const fetchTwitterAuthLink = createAsyncThunk(
  'twitter/fetchAuthLink',
  async domainId => {
    const response = await getTwitterAuthLink(domainId);
    return response.url;
  }
);

export const createTweet = createAsyncThunk(
  'twitter/createTweet',
  async ({ text, domainId }) => {
    const response = await postArticleTweet({ text, domainId });
    return response;
  }
);

export const removeTweet = createAsyncThunk(
  'twitter/removeTweet',
  async ({ tweetId, domainId }) => {
    const response = await deleteArticleTweet(tweetId, domainId);
    return response;
  }
);

const twitterAuthSlice = createSlice({
  name: 'twitterAuth',
  initialState: {
    authUrl: '',
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTwitterAuthLink.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchTwitterAuthLink.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.authUrl = action.payload;
      })
      .addCase(fetchTwitterAuthLink.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createTweet.fulfilled, (state, action) => {
        // Handle successful tweet creation
        console.log(state, action);
      })
      .addCase(removeTweet.fulfilled, (state, action) => {
        // Handle successful tweet deletion
        console.log(state, action);
      });
  }
});

export default twitterAuthSlice.reducer;
