import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Spinner,
  Form,
  Modal,
  Badge
} from 'react-bootstrap';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender
} from '@tanstack/react-table';
import { createUser, updateUser, deleteUser } from 'helpers/requests';
import {
  FaEdit,
  FaTrash,
  FaPlus,
  FaCheck,
  FaExclamation,
  FaChevronLeft,
  FaChevronRight
} from 'react-icons/fa';
import { getUsers } from 'redux/userSlice';
import moment from 'moment';

const Users = () => {
  // const { setConfig } = useAppContext();
  // setConfig('navbarPosition', 'vertical');
  const dispatch = useDispatch();
  const users = useSelector(state => state.user.users);
  const status = useSelector(state => state.user.status);
  const { page, limit } = useSelector(state => state.user);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    username: '',
    email: '',
    status: 'ACTIVE',
    role: 'user',
    domainsLimit: 1
  });
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    dispatch(getUsers({ page, limit }));
  }, [dispatch, page, limit]);

  const handleShowModal = (
    user = {
      username: '',
      email: '',
      status: 'ACTIVE',
      role: 'user',
      domainsLimit: 1
    }
  ) => {
    setCurrentUser(user);
    setIsEditMode(!!user._id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentUser({
      username: '',
      email: '',
      status: 'ACTIVE',
      role: 'user',
      domainsLimit: 1
    });
  };

  const handleSaveUser = async () => {
    try {
      if (isEditMode) {
        await updateUser(currentUser._id, currentUser);
      } else {
        await createUser(currentUser);
      }
      dispatch(getUsers({ page, limit }));
      handleCloseModal();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleDeleteUser = userId => {
    setDeleteUserId(userId);
    setShowDeleteModal(true);
  };

  const confirmDeleteUser = async () => {
    try {
      await deleteUser(deleteUserId);
      dispatch(getUsers({ page, limit }));
      setShowDeleteModal(false);
      setDeleteUserId(null);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const columns = [
    {
      accessorKey: 'username',
      header: 'Name'
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: ({ row }) => (
        <Badge bg="secondary">
          {row.original.email}{' '}
          {row.original.emailVerified ? (
            <FaCheck className="text-success ms-1" />
          ) : (
            <FaExclamation className="text-danger ms-1" />
          )}
        </Badge>
      )
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => (
        <Badge bg={row.original.status === 'ACTIVE' ? 'success' : 'danger'}>
          {row.original.status}
        </Badge>
      )
    },
    {
      accessorKey: 'role',
      header: 'Role'
    },
    {
      accessorKey: 'domainsLimit',
      header: 'Domains Limit'
    },
    {
      accessorKey: 'membership',
      header: 'Membership',
      cell: ({ row }) => <>{moment(row.original.createdAt).fromNow(true)}</>
    },
    {
      accessorKey: 'lastLogin',
      header: 'Last Login',
      cell: ({ row }) => {
        const daysSinceLastLogin = moment().diff(
          moment(row.original.lastLogin),
          'days'
        );
        return (
          <>
            {daysSinceLastLogin === 0
              ? 'Today'
              : daysSinceLastLogin === 1
              ? 'Yesterday'
              : `${daysSinceLastLogin} days ago`}
          </>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <>
          <FaEdit
            className="text-warning cursor-pointer"
            onClick={() => handleShowModal(row.original)}
          />{' '}
          <FaTrash
            className="text-danger cursor-pointer"
            onClick={() => handleDeleteUser(row.original._id)}
          />
        </>
      )
    }
  ];

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: { pagination: { pageSize: limit, pageIndex: page - 1 } }
  });

  const {
    getHeaderGroups,
    getRowModel,
    getState,
    setGlobalFilter,
    setPageSize,
    previousPage,
    nextPage,
    getCanPreviousPage,
    getCanNextPage
  } = table;

  const { pageIndex, pageSize, globalFilter } = getState().pagination;

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between mt-4">
          <Col sm={12} md={2}>
            <h3 className="text-primary">Users</h3>
          </Col>
          <Col sm={8} md={7}>
            <Form.Control
              type="text"
              placeholder="Search..."
              value={globalFilter || ''}
              onChange={e => setGlobalFilter(e.target.value)}
            />
          </Col>
          <Col className="text-end">
            <Button variant="primary" onClick={() => handleShowModal()}>
              <FaPlus /> Create New User
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {status === 'loading' ? (
              <Spinner animation="border" />
            ) : (
              <Table responsive>
                <thead>
                  {getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {getRowModel().rows.map(row => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Row className="pagination">
              <Col>
                <Button
                  className="btn-falcon-primary me-1"
                  onClick={() => previousPage()}
                  disabled={!getCanPreviousPage()}
                >
                  <FaChevronLeft color="black" />
                </Button>{' '}
                <Button
                  className="btn-falcon-primary me-1"
                  onClick={() => nextPage()}
                  disabled={!getCanNextPage()}
                >
                  <FaChevronRight color="black" />
                </Button>
              </Col>
              <Col className="text-end">
                <span>
                  {' '}
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {table.getPageCount()}
                  </strong>{' '}
                </span>
                <Form.Control
                  as="select"
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                  style={{ width: 'auto', display: 'inline-block' }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? 'Edit User' : 'Create User'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={currentUser.username}
                onChange={e =>
                  setCurrentUser({ ...currentUser, username: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={currentUser.email}
                onChange={e =>
                  setCurrentUser({ ...currentUser, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={currentUser.status}
                onChange={e =>
                  setCurrentUser({ ...currentUser, status: e.target.value })
                }
              >
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={currentUser.role}
                onChange={e =>
                  setCurrentUser({ ...currentUser, role: e.target.value })
                }
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDomainsLimit">
              <Form.Label>Domains Limit</Form.Label>
              <Form.Control
                type="number"
                value={currentUser.domainsLimit}
                onChange={e =>
                  setCurrentUser({
                    ...currentUser,
                    domainsLimit: e.target.value
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveUser}
            disabled={status === 'loading'}
          >
            {status === 'loading' ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Save Changes'
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default Users;
