import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Spinner, Badge, Button, Pagination } from 'react-bootstrap';
// import { getArticles, selectArticles, selectArticleStatus, selectArticleError } from 'redux/articlesSlice';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavbarStandard from './landing/NavbarStandard';
import FooterStandard from './landing/FooterStandard';
import Cta from './landing/Cta';
import {
  selectArticleStatus,
  selectArticles,
  selectArticleError,
  getArticles
} from 'redux/articleSlice';
import Hero from './landing/Hero';
import { baseUrl } from 'helpers/requests';

const Home = () => {
  // const { setConfig } = useAppContext();
  // setConfig('navbarPosition', 'top');
  const dispatch = useDispatch();
  const articles = useSelector(selectArticles);
  const status = useSelector(selectArticleStatus);
  const error = useSelector(selectArticleError);
  const { pages, limit } = useSelector(state => state.article);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getArticles({ page: currentPage, limit }));
  }, [dispatch, currentPage, limit]);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const formatDate = date => {
    return moment(date).format('Do MMMM');
  };
  return (
    <>
      <NavbarStandard />
      <Hero />
      <section>
        <div className="container">
          {status === 'loading' ? (
            <Spinner animation="border" />
          ) : status === 'failed' ? (
            <div>Error: {error}</div>
          ) : (
            <div className="row mb-4">
              {articles.map(article => (
                <Col md={6} lg={4} className="d-flex mb-4" key={article._id}>
                  <Card>
                    {article.image && article.image != '' ? (
                      <Link to={`/post/${article._id}`}>
                        <Card.Img
                          variant="top"
                          src={`${baseUrl}/images/` + article.image}
                          alt={article.imageFilename}
                        />
                      </Link>
                    ) : (
                      <Link to={`/post/${article._id}`}>
                        <Card.Img
                          variant="top"
                          src={article.images[0]}
                          alt={article.imageFilename}
                        />
                      </Link>
                    )}
                    <Card.Body className="d-flex flex-column">
                      <div className="d-flex justify-content-between mb-3">
                        <div className="text-small d-flex">
                          {article.categories.map((category, index) => (
                            <div className="me-1" key={index}>
                              <Link to="#">{category}</Link>
                            </div>
                          ))}
                        </div>
                        <Badge bg="primary-alt" text="primary">
                          {formatDate(article.dateTime)}
                        </Badge>
                      </div>
                      <Link to={`/post/${article._id}`}>
                        <Card.Title>{article.title}</Card.Title>
                      </Link>
                      <Card.Text className="flex-grow-1">
                        {article.summary}
                      </Card.Text>
                      <div className="d-flex align-items-center mt-3">
                        {/* <Image
                          src={article.userId.avatar || 'default-avatar.png'}
                          roundedCircle
                          width="40"
                          height="40"
                        /> */}
                        <div className="ml-1">
                          <span className="text-small text-muted me-2">By</span>{' '}
                          <span className="text-small me-1">
                            {article.userId.username}
                          </span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </div>
          )}
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <Button
                variant="outline-primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 1}
              >
                Previous
              </Button>
            </div>
            <div className="col-auto">
              <Pagination>
                {[...Array(pages).keys()].map(pageNumber => (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber + 1 === currentPage}
                    onClick={() => handlePageChange(pageNumber + 1)}
                  >
                    {pageNumber + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
            <div className="col-auto">
              <Button
                variant="outline-primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= pages}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </section>
      <Cta />
      <FooterStandard />
    </>
  );
};

export default Home;
