import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchArticleById, fetchUsers } from 'helpers/requests';

export const getUsers = createAsyncThunk('user/getUsers', async () => {
  const response = await fetchUsers();
  return response;
});

export const getUserById = createAsyncThunk(
  'user/getUserById',
  async userId => {
    const response = await fetchArticleById(userId);
    return response;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    user: null,
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    status: 'idle',
    error: null
  },
  reducers: {
    clearUser: state => {
      state.user = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUsers.pending, state => {
        state.status = 'loading';
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload.users;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.limit = action.payload.limit;
        state.total = action.payload.total;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getUserById.pending, state => {
        state.status = 'loading';
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearUser } = userSlice.actions;

export const selectUser = state => state.user.user;
export const selectUsers = state => state.user.users;
export const selectUserStatus = state => state.user.status;
export const selectUserError = state => state.user.error;

export default userSlice.reducer;
