import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchPosts,
  fetchPostById,
  createPost,
  updatePost,
  deletePost
} from 'helpers/requests';

// Async Thunks
export const getPosts = createAsyncThunk('post/getPosts', async data => {
  const response = await fetchPosts(data);
  return response;
});

export const getPostById = createAsyncThunk(
  'post/getPostById',
  async postId => {
    const response = await fetchPostById(postId);
    return response;
  }
);

export const addPost = createAsyncThunk('post/addPost', async postData => {
  const response = await createPost(postData);
  return response;
});

export const editPost = createAsyncThunk(
  'post/editPost',
  async ({ postId, postData }) => {
    const response = await updatePost(postId, postData);
    return response;
  }
);

export const removePost = createAsyncThunk('post/removePost', async postId => {
  const response = await deletePost(postId);
  return response;
});

// Slice
const postSlice = createSlice({
  name: 'post',
  initialState: {
    posts: [],
    post: null,
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // Fetch posts
      .addCase(getPosts.pending, state => {
        state.status = 'loading';
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts = action.payload.posts;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.limit = action.payload.limit;
        state.total = action.payload.total;
      })
      .addCase(getPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Fetch single post by ID
      .addCase(getPostById.pending, state => {
        state.status = 'loading';
      })
      .addCase(getPostById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.post = action.payload;
      })
      .addCase(getPostById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Create new post
      .addCase(addPost.pending, state => {
        state.status = 'loading';
      })
      .addCase(addPost.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts.push(action.payload);
      })
      .addCase(addPost.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Edit post
      .addCase(editPost.pending, state => {
        state.status = 'loading';
      })
      .addCase(editPost.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.posts.findIndex(
          post => post._id === action.payload._id
        );
        if (index !== -1) {
          state.posts[index] = action.payload;
        }
      })
      .addCase(editPost.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Delete post
      .addCase(removePost.pending, state => {
        state.status = 'loading';
      })
      .addCase(removePost.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts = state.posts.filter(
          post => post._id !== action.payload._id
        );
      })
      .addCase(removePost.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Selectors
export const selectPosts = state => state.post.posts;
export const selectPostStatus = state => state.post.status;
export const selectPostError = state => state.post.error;

export default postSlice.reducer;
