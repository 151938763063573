import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Spinner,
  Form,
  Modal,
  Badge
} from 'react-bootstrap';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender
} from '@tanstack/react-table';
import { deletePost } from 'helpers/requests';
import { FaTrash, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { getPosts } from 'redux/postSlice';
import moment from 'moment';

const Posts = () => {
  // const { setConfig } = useAppContext();
  // setConfig('navbarPosition', 'vertical');
  const dispatch = useDispatch();
  const posts = useSelector(state => state.post.posts);
  const status = useSelector(state => state.post.status);
  const { page, pages, limit, total } = useSelector(state => state.post);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePostId, setDeletePostId] = useState(null);

  useEffect(() => {
    dispatch(getPosts({ page, limit }));
  }, [dispatch, page, limit]);

  const handleDeletePost = postId => {
    setDeletePostId(postId);
    setShowDeleteModal(true);
  };

  const confirmDeletePost = async () => {
    try {
      await deletePost(deletePostId);
      dispatch(getPosts({ page, limit }));
      setShowDeleteModal(false);
      setDeletePostId(null);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const columns = [
    {
      accessorKey: 'postUrl',
      header: 'Post URL',
      cell: ({ row }) => (
        <>
          <div className="d-flex align-items-center">
            <div>
              <a
                href={row.original.postUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.original.postUrl}
              </a>
            </div>
          </div>
        </>
      )
    },
    {
      accessorKey: 'domainId.domainUrl',
      header: 'Domain'
    },
    {
      accessorKey: 'websiteId.websiteUrl',
      header: 'Website'
    },
    {
      accessorKey: 'userId.username',
      header: 'Username'
    },
    {
      accessorKey: 'isProcessed',
      header: 'Status',
      cell: ({ row }) => (
        <Badge bg={row.original.isProcessed ? 'success' : 'warning'}>
          {row.original.isProcessed ? 'Processed' : 'Not Processed'}
        </Badge>
      )
    },
    {
      accessorKey: 'retries',
      header: 'Retries'
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      cell: ({ row }) => {
        const now = moment();
        const createdAt = moment(row.original.createdAt);
        const duration = moment.duration(now.diff(createdAt));

        let timeAgo;
        if (duration.asMinutes() < 60) {
          const minutes = Math.floor(duration.asMinutes());
          timeAgo = minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
        } else if (duration.asHours() < 24) {
          const hours = Math.floor(duration.asHours());
          timeAgo = hours === 1 ? '1 hour ago' : `${hours} hours ago`;
        } else if (duration.asDays() < 7) {
          const days = Math.floor(duration.asDays());
          timeAgo = days === 1 ? '1 day ago' : `${days} days ago`;
        } else if (duration.asWeeks() < 4) {
          const weeks = Math.floor(duration.asWeeks());
          timeAgo = weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
        } else if (duration.asMonths() < 12) {
          const months = Math.floor(duration.asMonths());
          timeAgo = months === 1 ? '1 month ago' : `${months} months ago`;
        } else {
          const years = Math.floor(duration.asYears());
          timeAgo = years === 1 ? '1 year ago' : `${years} years ago`;
        }

        return <>{timeAgo}</>;
      }
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <div className="text-end">
          <FaTrash
            className="text-danger cursor-pointer"
            onClick={() => handleDeletePost(row.original._id)}
          />
        </div>
      )
    }
  ];

  const table = useReactTable({
    data: posts,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: { pagination: { pageSize: limit, pageIndex: page - 1 } }
  });

  const {
    getHeaderGroups,
    getRowModel,
    getState,
    setGlobalFilter,
    setPageSize,
    setPageIndex,
    previousPage,
    nextPage
  } = table;

  const { pageIndex, pageSize, globalFilter } = getState().pagination;

  useEffect(() => {
    dispatch(getPosts({ page: pageIndex + 1, limit: pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between mt-4">
          <Col sm={12} md={2}>
            <h3 className="text-primary">Posts</h3>
          </Col>
          <Col sm={8} md={7}>
            <Form.Control
              type="text"
              placeholder="Search..."
              value={globalFilter || ''}
              onChange={e => setGlobalFilter(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {status === 'loading' ? (
              <Spinner animation="border" />
            ) : (
              <Table responsive>
                <thead>
                  {getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {getRowModel().rows.map(row => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Row className="pagination">
              <Col>
                <Button
                  className="btn-falcon-primary me-1"
                  onClick={() => previousPage()}
                  disabled={page <= 1}
                >
                  <FaChevronLeft color="black" />
                </Button>{' '}
                <Button
                  className="btn-falcon-primary me-1"
                  onClick={() => nextPage()}
                  disabled={page >= pages}
                >
                  <FaChevronRight color="black" />
                </Button>
              </Col>
              <Col className="text-end">
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pages}
                  </strong>{' '}
                </span>
                <Form.Control
                  as="select"
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                    setPageIndex(0); // Reset to first page on page size change
                  }}
                  style={{ width: 'auto', display: 'inline-block' }}
                >
                  {[10, 20, 30, 40, 50, 100, 500, 1000].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-end">
                <span>
                  Showing {pageIndex * pageSize + 1} to{' '}
                  {Math.min((pageIndex + 1) * pageSize, total)} of {total}{' '}
                  entries
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this post?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeletePost}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default Posts;
