import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDomains } from 'helpers/requests';

export const getDomains = createAsyncThunk('domain/getDomains', async () => {
  const response = await fetchDomains();
  return response;
});

const domainSlice = createSlice({
  name: 'domain',
  initialState: {
    domains: [],
    domain: null,
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDomains.pending, state => {
        state.status = 'loading';
      })
      .addCase(getDomains.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.domains = action.payload.domains;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.limit = action.payload.limit;
        state.total = action.payload.total;
      })
      .addCase(getDomains.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const selectDomains = state => state.domain.domains;
export const selectDomainStatus = state => state.domain.status;
export const selectDomainError = state => state.domain.error;

export default domainSlice.reducer;
