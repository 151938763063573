import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTweets } from 'helpers/requests';

export const getTweets = createAsyncThunk('tweet/getTweets', async data => {
  const response = await fetchTweets(data);
  return response;
});

const tweetSlice = createSlice({
  name: 'tweet',
  initialState: {
    tweets: [],
    tweet: null,
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTweets.pending, state => {
        state.status = 'loading';
      })
      .addCase(getTweets.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tweets = action.payload.tweets;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.limit = action.payload.limit;
        state.total = action.payload.total;
      })
      .addCase(getTweets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const selectTweets = state => state.tweet.tweets;
export const selectTweetStatus = state => state.tweet.status;
export const selectTweetError = state => state.tweet.error;

export default tweetSlice.reducer;
