import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchWorkerStates,
  fetchWorkerStateById,
  createWorkerState,
  updateWorkerState,
  deleteWorkerState
} from 'helpers/requests';

// Async Thunks
export const getWorkerStates = createAsyncThunk(
  'workerState/getWorkerStates',
  async data => {
    const response = await fetchWorkerStates(data);
    return response;
  }
);

export const getWorkerStateById = createAsyncThunk(
  'workerState/getWorkerStateById',
  async workerStateId => {
    const response = await fetchWorkerStateById(workerStateId);
    return response;
  }
);

export const addWorkerState = createAsyncThunk(
  'workerState/addWorkerState',
  async workerStateData => {
    const response = await createWorkerState(workerStateData);
    return response;
  }
);

export const editWorkerState = createAsyncThunk(
  'workerState/editWorkerState',
  async ({ workerStateId, workerStateData }) => {
    const response = await updateWorkerState(workerStateId, workerStateData);
    return response;
  }
);

export const removeWorkerState = createAsyncThunk(
  'workerState/removeWorkerState',
  async workerStateId => {
    const response = await deleteWorkerState(workerStateId);
    return response;
  }
);

// Slice
const workerStateSlice = createSlice({
  name: 'workerState',
  initialState: {
    workerStates: [],
    workerState: null,
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // Fetch worker states
      .addCase(getWorkerStates.pending, state => {
        state.status = 'loading';
      })
      .addCase(getWorkerStates.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workerStates = action.payload.workerStates;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.limit = action.payload.limit;
        state.total = action.payload.total;
      })
      .addCase(getWorkerStates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Fetch single worker state by ID
      .addCase(getWorkerStateById.pending, state => {
        state.status = 'loading';
      })
      .addCase(getWorkerStateById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workerState = action.payload;
      })
      .addCase(getWorkerStateById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Create new worker state
      .addCase(addWorkerState.pending, state => {
        state.status = 'loading';
      })
      .addCase(addWorkerState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workerStates.push(action.payload);
      })
      .addCase(addWorkerState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Edit worker state
      .addCase(editWorkerState.pending, state => {
        state.status = 'loading';
      })
      .addCase(editWorkerState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.workerStates.findIndex(
          workerState => workerState._id === action.payload._id
        );
        if (index !== -1) {
          state.workerStates[index] = action.payload;
        }
      })
      .addCase(editWorkerState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Delete worker state
      .addCase(removeWorkerState.pending, state => {
        state.status = 'loading';
      })
      .addCase(removeWorkerState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workerStates = state.workerStates.filter(
          workerState => workerState._id !== action.payload._id
        );
      })
      .addCase(removeWorkerState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Selectors
export const selectWorkerStates = state => state.workerState.workerStates;
export const selectWorkerStateStatus = state => state.workerState.status;
export const selectWorkerStateError = state => state.workerState.error;

export default workerStateSlice.reducer;
