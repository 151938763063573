import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchArticles } from 'helpers/requests';

export const getArticles = createAsyncThunk(
  'article/getArticles',
  async data => {
    const response = await fetchArticles(data);
    return response;
  }
);

const articleSlice = createSlice({
  name: 'article',
  initialState: {
    articles: [],
    article: null,
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getArticles.pending, state => {
        state.status = 'loading';
      })
      .addCase(getArticles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.articles = action.payload.articles;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.limit = action.payload.limit;
        state.total = action.payload.total;
      })
      .addCase(getArticles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const selectArticles = state => state.article.articles;
export const selectArticleStatus = state => state.article.status;
export const selectArticleError = state => state.article.error;

export default articleSlice.reducer;
